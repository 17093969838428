//値を変更して確認するダイアログ呼び出し
import React, { useState, useRef } from 'react';
import { API} from '../utils/Constants';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import axios from 'axios';

const SelectConfirm = ({ initialValue, apiEndpoint, options, label ,org_id, plat_code,callback }) => {
  let [selectedValue, setSelectedValue] = useState(initialValue);
  let [openDialog, setOpenDialog] = useState(false);
  let [dialogValue, setDialogValue] = useState(initialValue);
  const selectRef = useRef(null);
  const keyValuePairs = Object.entries(options);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setDialogValue(newValue);
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    setOpenDialog(false);
    setSelectedValue(dialogValue);
    const postdata ={
      "org_id" : org_id,
      "plat_code" : plat_code,
      "del_flag": dialogValue.toString()
    }
    await axios.post(API.ENDPOINT+apiEndpoint
      ,postdata
      ,{ headers: {'Content-Type': 'application/json', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
      .then(res => {
        callback()
      }
    ).catch(err => {
        throw err;
      }
    )
  };

  const handleCancel = () => {
    setOpenDialog(false);
      // Material UIのSelectコンポーネントの値を強制的に戻す
      if (selectRef.current) {
      selectRef.current.value = selectedValue;
    }
  };

    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id={`${plat_code}-label`}>{label}</InputLabel>
        <Select
          labelId={`${plat_code}-label`}
          id={plat_code+"_select"}
          value={selectedValue}
          onChange={handleChange}
          ref={selectRef}
        >
          {keyValuePairs.map(([key,value])=> (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
        <Dialog open={openDialog} onClose={handleCancel}>
          <DialogTitle>確認</DialogTitle> 
          <DialogContent>
              変更します。即時に反映されますがよろしいですか？?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirm} color="primary">
              Yes
            </Button>
            <Button onClick={handleCancel} color="primary">
                No
            </Button>
          </DialogActions>
        </Dialog>
    </div>
    );
};

export default SelectConfirm;