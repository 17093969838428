//サービスID毎の契約更新する
import React, { useState, useRef } from 'react';
import {API, SERVICE_ID} from '../utils/Constants';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';

const UpdateDeviceContractConfirm = ({ org_id, operator, service_id,disable, callback }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState(null);
  

  const getPlanNameByServiceId = (value) => {
    return Object.keys(SERVICE_ID).find(key => SERVICE_ID[key] === value);
  }

  const planName = getPlanNameByServiceId(service_id);

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  };

  const handleClick = (event) => {
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    if(!selectedValue){
      setDialogErrorMessage("更新後の"+{planName}+"数を入力してください。")
      return;
    }

    setOpenDialog(false);
    const postdata ={
      "org_id" : org_id,
      "service_id" : service_id,
      "operator": operator,
      "new_num": selectedValue
    }
    await axios.put(API.ENDPOINT+ '/v1/org/laap'
      ,postdata
      ,{ headers: {'Content-Type': 'application/json', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
      .then(res => {
        setDialogErrorMessage(null);
        callback(true,"更新が完了しました。")
      }
    ).catch(err => {
        callback(false,err.message);
      }
    )
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <div>
    <Button variant="contained" onClick={() => (handleClick()) } disabled={disable} >{planName}契約数更新</Button>
    <strong>
    <Dialog open={openDialog} onClose={() => (handleCancel())}>
      <DialogTitle>{planName}契約数の更新</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {planName}の契約数を更新します。<br/>
          Airtableには反映されません。
          <br /><font color="red">{dialogErrorMessage}</font>
        </DialogContentText>
        <TextField
          required
          autoFocus
          margin="dense"
          id="new_num"
          label="変更後の数"
          type="number"
          fullWidth
          variant="standard"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>キャンセル</Button>
        <Button onClick={handleConfirm}>登録</Button>
      </DialogActions>
    </Dialog>
    </strong>
    </div>
  );
};

export default UpdateDeviceContractConfirm;